import React from 'react'


const FormSelect = ({
  name,
  label,
  handleChange,
  options,
  validationRule,
  errorMessage,
  value,
  style,
  disabled = false,
  classname,
  required
}) => {
  return (
    <div className="form__group" style={style}>
      <div className="form__group-control">

        {label && (
          <label htmlFor={name} className="form__label">
            {label || name}
            {required && <span className="ml-1 after:content-['*'] after:ml-0.5 after:text-red-500"></span>}
          </label>
        )}

        <select
          className={errorMessage ? `${classname} border border-red-600` : classname}
          name={name}
          id={name}
          onChange={handleChange}
          data-validate={validationRule}
          disabled={disabled}
          value={value || 'select'}
        >
          <option value='select' key='select'>Selectionnez</option>
          {options.map((option) => {
            let item = option
            /* option.includes('-') ? (item = option.split('-')[1].trim()) : item */
            return (
              <option value={item} key={item} >{item}</option>
            )
          })}
        </select>
        {errorMessage && <p className="text-red-800  mt-0 pt-1 text-base">{errorMessage}</p>}
      </div>
    </div>
  )
}

export default FormSelect

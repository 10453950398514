import React, { useState } from 'react'
import Layout from '../layouts/layout'
import { btnOutline, btnPrimary, section, input as cssClass } from '../styles/styles'
//https://www.gatsbyjs.com/docs/building-a-contact-form/#run-your-own-server
// https://medium.com/datafire-io/simple-backends-four-ways-to-implement-a-contact-us-form-on-a-static-website-10fc430984a4
//https://dev.to/mertcanyucel/create-and-deploy-gatsby-js-forms-using-getform-with-vercel-fec

import { getFunctions, httpsCallable } from "firebase/functions";
import firebase from '../firebase'
// https://www.youtube.com/watch?v=Sb2pHY-lbv0
import useForm from '../hooks/useForm'
import { checkInputs } from '../hooks/useForm/validation'

import { formInputs, getInitialState, columns } from '../content/contact'

import FormField from '../components/form-field'
import FormSelect from '../components/form-select'
import Message from '../components/message'



const Contact = () => {

    const [formState, setFormState] = useState(getInitialState())
    const [showMessage, setShowMessage] = useState(false)

    const { values, errors, handleChange, formSubmit, isValid } = useForm(
        handleSubmit,
        // if new record initilState ? the fetched data
        formState
    )


    const validateInputs = () => {
        const requiredInputs = columns.filter((col) => col.rule).map((item) => item.name)
        const inputs = requiredInputs.reduce(
            (acc, curr) => ((acc[curr] = values[curr]), acc),
            {}
        )
        return inputs
    }


    async function handleSubmit() {
        const errs = checkInputs(validateInputs())
        if (Object.keys(errs).length > 0) return
        setFormState(getInitialState())
        setShowMessage(true)
        console.log('submitted')

        /* const functions = getFunctions();
        const sendEmail = httpsCallable(functions, 'contactForm') */
        /*   sendEmail({ text: 'messageText' })
              .then((result) => {
                 
                  const data = result.data;
                  console.log(data)
              })
              .catch((error) => {
                  // Getting the Error details.
                  var code = error.code;
                  var message = error.message;
                  var details = error.details;
                  // ...
                  console.log("error.message: " + error.message + " error.code: " + error.code + " error.details: " + error.details)
                  console.log(error);
                  // ...
              });
          */
        /*   fetch('https://us-central1-knrevents-1bb2e.cloudfunctions.net/sendEmailContact', {
              method: 'post',
              body: JSON.stringify(values),
              headers: { 'Content-Type': 'application/json', }
          }).then(res => console.log(res)).catch(err => console.log(err)) */

    }

    return (
        <Layout>
            <section className={`${section} `}>
                <div className="px-0 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                    <div className="px-0 mx-auto max-w-screen-2xl sm:px-6 lg:px-8 ">

                        <div className="px-0 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                            <div className="max-w-2xl mx-auto text-center">
                                <h2 className="title sm:text-4xl lg:text-5xl">Contacter nous</h2>

                            </div>

                            <div className="px-4 max-w-5xl mx-auto mt-12 sm:mt-16">
                                <div className="grid grid-cols-1 gap-6 px-2 text-center md:px-0 md:grid-cols-2">
                                    <div className="overflow-hidden bg-white rounded-xl">
                                        <div className="p-6">
                                            <svg className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1"
                                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                />
                                            </svg>
                                            <p className="mt-6 text-lg font-medium text-[#0c1934]">+212 650 557 096</p>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden bg-white rounded-xl">
                                        <div className="p-6">
                                            <svg className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                            </svg>
                                            <p className="mt-6 text-lg font-medium text-[#0c1934]">contact@knrevents.com</p>
                                        </div>
                                    </div>


                                </div>

                                <div className="mt-6 overflow-hidden bg-white rounded-xl">
                                    <div className="px-6 py-8 sm:p-12 ">
                                        <h3 className="text-3xl  text-center text-[#0c1934]">Nous aimerions vous écouter!</h3>
                                        {showMessage &&
                                            <div className='w-[95%] sm:w-[90%]md:w-[85%] lg:[600px] mx-auto text-center p-1  sm:px-10  border border-green-600 bg-white mt-3'>
                                                <p className='dark mt-0 text-green-600'>Votre message est envoyé avec succéss.</p>
                                                <p className='dark mt-0 text-green-600'>
                                                    Nous reviendrons vers vous dans les plus brefs délais.
                                                </p>
                                            </div >
                                        }

                                        <form action='/api/sendmail' method="POST" className="mt-14" onSubmit={formSubmit}>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                                                {
                                                    formInputs.map((input) => {
                                                        const { label, name, type, placeholder, validationRule } = input

                                                        return (type !== 'textarea') ? (
                                                                    <FormField
                                                                        label={label}
                                                                        type={type}
                                                                        name={name}
                                                                        classname={cssClass}
                                                                        placeholder={placeholder}
                                                                        errorMessage={errors[name]}
                                                                        validationRule={validationRule}
                                                                        value={values ? values[name] : ''}
                                                                        handleChange={handleChange}
                                                                        required={validationRule ? true : false}
                                                            />
                                                        ) : (
                                                                <div className='col-span-1 sm:col-span-2'>
                                                                        <textarea
                                                                            name={name}
                                                                            placeholder={placeholder}
                                                                            onChange={handleChange}
                                                                            data-validate={validationRule}
                                                                            className={`block w-full px-4 py-4 input ${cssClass}`}
                                                                            rows="4" ></textarea>
                                                                    {errors[name] && <p className="text-red-800  mt-0 pt-1 text-base">{errors[name]}</p>}
                                                                </div>
                                                        )
                                                    })
                                                }
                                                <div className="mt-4">
                                                    <button type="submit" className={`${btnPrimary} sm:w-full rounded-md py-0 sm:py-1`}
                                                        disabled={!isValid}
                                                    >
                                                        Inscription
                                                    </button>
                                                </div>
                                            </div> 
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contact
import React from 'react'

const Message = () => {
    return (

        <div className='w-[95%] sm:w-[600px] mx-auto text-center px-2 py-6 sm:px-10 sm:py-8 border bg-white'>
            <p className='dark mt-0'>Merci de votre inscription</p>
            <p className='dark mt-0'>Nous accusons la réception de votre demande d'inscription au
                <span className='font-medium block text-xl text-primary'>Tafraout CampTRAIL & BIKE du 10 au 12 Mars 2023.</span></p>
            <p className='dark mt-3 font-medium'>Equipe KNR Events</p>
            <p className='dark mt-0 font-medium'>GSM : +212 650 557 096</p>
            <p className='dark mt-0 font-medium'>E-mail : contact@knrevents.com </p>
        </div >

    )
}

export default Message
// data form
export const columns = [
    { name: 'nom', label: 'Nom & Prénom', rule: 'isRequired', type: 'text' },
    { name: 'email', label: 'Votre email', rule: 'isRequired', type: 'email' },
    { name: 'phone', label: 'Votre phone', rule: 'isRequired', type: 'text' },
    { name: 'sujet', label: 'Sujet', rule: 'isRequired', type: 'text' },
    { name: 'message', label: 'Votre message', rule: 'isRequired', type: 'textarea' },
]



export const formInputs =
    // inputs type text
    columns
        .map((col) => {
            const { name, rule, label, type, options } = col
            return {
                label: label,
                type: type ? type : 'text',
                name: name,
                placeholder: label,
                options: options,
                // check if there is a validation rule for the
                // input and set the validatione rule
                ...(rule && { validationRule: rule }),
            }
        })


///////////////////////////////////////
export const getInitialState = () => {
    let initialState = {}
    // remove radio button name from initialState
    const initialInputs = formInputs
        .filter((el) => el.name !== 'product')
        .map((col) => col.name)
    for (const value of initialInputs) {
        initialState = { ...initialState, [value]: '' }
    }
    return initialState
}


